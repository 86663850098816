.modal-backdrop {
  opacity: 0 !important;
  z-index: 999 !important;
  /* --bs-backdrop-bg: rgb(255, 255, 255) !important;
  --bs-backdrop-opacity: 0 !important; */
}

/* .background.modal-backdrop {
  opacity: 0 !important;
  z-index: 1 !important;
} */

/* .offcanvas-lg {
  z-index: 1000 !important;
} */

.notification-container {
  transition: ease;
  opacity: 0;
  transform: translateY(250%);
  position: fixed;
  right: 3%;
  bottom: 3%;
  margin-left: -175px;
  z-index: 10000000 !important;
}

.notification-container.show {
  animation: slide-up 0.35s ease forwards;
}

.notification-container.hide {
  animation: slide-down 0.35s ease forwards;
}

.notification-message {
  color: #fff;
  height: 55px;
  border-radius: 3px;
  display: grid;
  align-items: center;
  grid-template-columns: 85% 15%;
  text-align: center;
  width: 350px;
}

.close-btn {
  color: #fff;
  border: none;
  cursor: pointer;
  height: 54.5px;
  opacity: 1;
  border-radius: 3px;
  width: 54px;
  transform: translateY(-1px);
  z-index: 1000 !important;
}

.load {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.loading {
  margin: auto;
  border: 4px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-up {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(250%);
    opacity: 0;
  }
}

@media all and (max-width: 700px) {
  .notification-container {
    left: 50%;
  }
}