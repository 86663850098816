.topfront-background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.contactImage.contactImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 50% 40%;
    z-index: -1;
}

.contactIntro.contactIntro {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.0);
    animation: openContactCard 1.7s ease forwards;
}

.contactIntro-body {
    margin: auto;
    animation: fadeDown 1.7s ease forwards;
}

@keyframes fadeDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }

    70% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes openContactCard {
    0% {
        -webkit-transform: translate(-50%, -40%);
        transform: translate(-50%, -40%);
        width: 0px;
        height: 100px;
        padding: 0;
    }

    70% {
        -webkit-transform: translate(-50%, -40%);
        transform: translate(-50%, -40%);
        width: 200px;
        height: 100px;
        padding: 30px;
    }

    100% {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 200px;
        height: 100px;
        padding: 30px;
    }

}

@media all and (max-width: 460px) {
    @keyframes openContactCard {
        0% {
            width: 0px;
            height: 100px;
            padding: 0;
        }

        70% {
            width: 200px;
            height: 100px;
            padding: 30px;
        }

        100% {
            width: 200px;
            height: 100px;
            padding: 30px;
        }

    }

    @keyframes fadeDown {
        0% {
            opacity: 0;
            -webkit-transform: translateY(50px);
            transform: translateY(50px);
        }

        75% {
            opacity: 0;
            -webkit-transform: translateY(50px);
            transform: translateY(50px);
        }

        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
    }

}