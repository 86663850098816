/* .circle {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.spinningCircle {
  margin: auto;
  border: 4px solid black;
  border-radius: 50%;
  border-top: 4px solid rgb(0, 214, 0);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
/* animation: spin 2s linear infinite;
} */

#loading {
  background: inherit;
  width: 100%;
}

.loading-object {
  position: absolute;
  margin: auto;
  width: 100px;
  height: 4px;
  border-radius: 10%;
  margin: auto;
  transform: translateX(-100px);
  animation: load 2s infinite;
}

@keyframes load {
  100% {
    transform: translateX(calc(100vw));
  }
}