.customSubmitButton {
  width: 200px;
  color: black;
}

.customSubmitButton:hover {
  opacity: 0.7;
  transition: ease-in-out 0.4s;
}

.customButton {
  width: 200px;
  color: black;
}

.smallCustomButton {
  width: 100px;
  height: 40px;
  color: black;
  font-size: 14px;
}

.smallSubmitButton {
  width: 100px;
  height: 40px;
  color: black;
  font-size: 14px;
}