body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customButton:hover {
  opacity: 0.8;
  transition: ease 0.4s;
}

.colorInput {
  position: absolute;
  bottom: 10%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.colorInput::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}

.colorInput::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 50%;
  padding: 0;
}