/* SMARTPHONE DROP DOWN ----------------------------------------------------------------------------------- */

.hamburger {
  position: absolute;
  border: none;
  top: 5px;
  right: 1.3rem;
  padding: 4px;
  z-index: 100;
  background: transparent;
  cursor: pointer;
  display: none;
}

@media all and (max-width: 800px) {
  .hamburger {
    display: block;
  }

  .nav-link {
    padding: 0 !important;
  }
}

.offcanvas_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80px;
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 15px !important;
  cursor: pointer;
}

.offcanvas-item-title {
  width: 80px;
  text-align: center;
}

.off-canvas-container {
  transition: ease;
  opacity: 0;
  transform: translateX(250%);
  position: fixed;
  right: 3%;
  top: 3%;
  z-index: 10000000 !important;
}

.off-canvas-body {
  color: white;
  height: fit-content;
  width: 80px;
  border-radius: 3px;
  text-align: center;
}

.off-canvas-container.show {
  animation: slide-left 0.35s ease forwards;
}

.off-canvas-container.hide {
  animation: slide-right 0.35s ease forwards;
}

@keyframes slide-left {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(250%);
    opacity: 0;
  }
}