.topfront-background {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.coverImage.coverImage {
  width: 100%;
  height: 650px;
  object-fit: cover;
}

.row.row {
  --bs-gutter-x: 0;
}

.cover-card.cover-card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0);
  animation-delay: 0.3s;
  margin: auto;
  animation: openCard 1.7s ease forwards;
}

.frontPage-cardBody {
  animation: fadeDown 1.7s ease forwards;
}

.firstTitle {
  color: white;
}

.secondTitle {
  color: white;
  word-wrap: break-word;
  max-width: 500px;
  text-align: left;
}

.secondTitle .subtitle {
  font-size: 20px;
}

.secondTitle br {
  margin-bottom: 5px;
}

.Intro-button {
  margin-right: auto;
  margin-top: 20px;
}

.hr {
  margin-top: 30px !important;
  border-width: 2px;
  height: 0px;
  width: 110px;
  opacity: 1;
}

.service-picture.service-picture {
  object-fit: cover;
  object-fit: 0 0;
  width: 100%;
  height: 100%;
}

.service-card.service-card {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  color: white;
  background-color: rgba(12, 17, 26, 0.2);
  width: 300px;
  height: 300px;
  transform: translateY(30px);
  display: none;
  opacity: 0;
}

.service-card.visible {
  display: block;
  animation: fadeUp 2s forwards;
}

.service-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 120px;
  text-align: center;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  background-color: rgba(12, 17, 26, 0.5);
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  70% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes openCard {
  0% {
    -webkit-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
    width: 0px;
    height: 300px;
    padding: 0;
  }

  70% {
    -webkit-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
    width: 450px;
    height: 300px;
    padding: 30px;
  }

  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 450px;
    height: 300px;
    padding: 30px;
  }

}


@media all and (max-width: 460px) {

  @keyframes openCard {
    0% {
      width: 0px;
      padding: 0;
      height: 360px;
    }

    70% {
      width: 360px;
      height: 360px;
      padding: 30px;
    }

    100% {
      width: 360px;
      height: 360px;
      padding: 30px;
    }

  }

  @keyframes fadeDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
    }

    75% {
      opacity: 0;
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@media all and (max-width: 770px) {}

@media all and (max-width: 500px) {}