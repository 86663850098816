.forgotPasswordButton {
  width: 100px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  margin: auto;
  transform: translateY(50px);
  cursor: pointer;
  color: blue;
}

.forgotPasswordButton:hover {
  opacity: 0.7;
}

.createAccountButton {
  font-size: 14px !important;
  text-align: center;
  padding-left: 10px !important;
  cursor: pointer;
  color: blue;
}

.createAccountButton:hover {
  opacity: 0.7;
}